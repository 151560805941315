<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        flat 
        class="mb-3 title" 
        hide-default-footer
        loader-height="1"
        :loading="loading"
        :headers="headers" 
        :items="referenceUnits.data"
      >
        <template v-slot:item.product="{ item }">
          {{ item.product.name }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            dark
            label
            small
            color="primary"
            class="caption ttn"
            @click="edit(item)"
          >
            Edit  
          </v-btn> 
        </template>
      </v-data-table>

      <app-pagination
        v-if="referenceUnits.meta"
        :meta="referenceUnits.meta"
        @pageChanged="pageChanged"
      ></app-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      page: 1,
      loading: true,
      headers: [
        { text: 'Reference unit', value: 'name' },
        { text: 'Product', value: 'product' },
        // { text: 'Actions', value: 'actions' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      referenceUnits: 'getReferenceUnits'
    })
  },

  methods: {
    ...mapActions([
      'setReferenceUnits',
    ]),

    loadReferenceUnits () {
      this.setReferenceUnits({
        page: this.page,
      }).then(() => {
        this.loading = false
      })
    },

    pageChanged (page) {
      this.page = page
      this.loading = true
      this.loadReferenceUnits()
    },
  },

  mounted () {
    this.loadReferenceUnits()
  }
}
</script>